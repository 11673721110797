<template>
    <div>

        首页，内容待定
    </div>
</template>

<script>

    export default {

        created() {

            //console.log('runTime:',sessionStorage.getItem("runTime"));
            this.$_findRoleVersion();
            if (sessionStorage.getItem("runTimeTest") == 'true' || sessionStorage.getItem("runTimeTest") == true) {
                this.$store.dispatch('asyncUpdateRunTimeTest', true);
           } else {
                this.$store.dispatch('asyncUpdateRunTimeTest', false);
           }
            this.$_searchCustomerList('');
            this.$_searchCategoryList('');
            this.$_searchUnitList('');
            this.$_searchSupplierList('');
            this.$_searchCustomerAndSupplier('');
            this.$_searchByTotalSingleCount('');
            this.$_searcWarehouseList('');
            this.$_getAccountListByRoleId('10');//业务员
            this.$_getAccountListByRoleId('14');//开单员
            this.$_getAccountListByRoleId('16');//经手人
            this.$_getBillingCompanySettingList('');
            this.$_getClearingFormList('');
            this.$_getOtherExpensesList('');
            this.$_searchAccountList('');
       }
   };


</script>

<style scoped>

</style>
